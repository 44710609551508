import * as React from 'react'
// import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
// import IndexLayout from '../layouts'
import under_construction from '../assets/under_construction.png';

const IndexPage = () => (
    // <IndexLayout>
    <Page>
        <Container>
            <img src={under_construction} />
            <h1>This website is under construction...</h1>
            <p> See you soon!</p>
            {/* <Link to="/page-2/">Go to page 2</Link> */}
        </Container>
    </Page>
    // </IndexLayout>
)

export default IndexPage
